.root {
  --clr: unset;

  color: var(--clr);
  position: relative;

  &.lightning {
    width: fit-content; /* Для молнии, чтобы не расширялся на всю длину родителя */
  }
}

.lightIcon {
  height: 13px;
  width: 100%;
  position: absolute;
  bottom: calc(-1rem + 4px);
  left: 0;
}

.headerVariant-Vasek {
  font-family: 'Vasek', sans-serif;
  --clr: var(--clr-red);
}

.headerVariant-Grandis {
  font-family: 'Grandis Extended', sans-serif;
  text-transform: uppercase;
  --clr: var(--clr-blue);
}

.color-lightgrey {
  --clr: #C4C4C4
}

.color-grey {
  --clr: #767676;
}

.color-black {
  --clr: #313131;
}

.color-red {
  --clr: var(--clr-red);
}

.color-blue {
  --clr: var(--clr-blue);
}

.color-orange {
  --clr: #dfa32f;
}

.color-green {
  --clr: #5abb5e;
}

.color-white {
  --clr: #fff;
}

.variant-h1 {
  &.headerVariant-Vasek {
    font-weight: 400;
    line-height: 64px;
    font-size: 80px;
  }

  &.headerVariant-Grandis {
    font-size: 34px;
    font-weight: 700;
    line-height: 37px;
  }

  @media (max-width: 960px) {
    &.headerVariant-Vasek {
      line-height: 27px;
      font-size: 34px;
    }

    &.headerVariant-Grandis {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.variant-h2 {
  &.headerVariant-Vasek {
    font-weight: 400;
    line-height: 48px;
    font-size: 60px;
  }

  &.headerVariant-Grandis {
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
  }

  @media (max-width: 960px) {
    &.headerVariant-Vasek {
      font-weight: 400;
      line-height: 48px;
      font-size: 34px;
    }

    &.headerVariant-Grandis {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.variant-h3 {
  &.headerVariant-Grandis {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  @media (max-width: 960px) {
    &.headerVariant-Grandis {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.variant-h4 {
  &.headerVariant-Grandis {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  @media (max-width: 960px) {
    &.headerVariant-Grandis {
      font-size: 16px;
    }
  }
}

.variant-bodyXl {
  font-family: 'Inter';
  font-size: 22px;
  font-weight: 400;
  line-height: 31px;
}

.variant-bodyL {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.variant-bodyM {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.variant-bodyS {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.variant-subtitleL {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 17px;
  }
}

.variant-subtitleS {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 21px;
  }
}
